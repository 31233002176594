<template>
<svg xmlns="http://www.w3.org/2000/svg" width="12.65" height="12.65" viewBox="0 0 12.65 12.65">
  <defs>
    <clipPath id="lusdulvo0a">
      <path data-name="사각형 43492" transform="translate(-1 -1)" style="fill:none;stroke-width:.6px" d="M0 0h12.65v12.65H0z" stroke="currentColor"/>
    </clipPath>
  </defs>
  <g data-name="그룹 17413">
    <g data-name="그룹 17385" transform="translate(1 1)" style="clip-path:url(#lusdulvo0a)">
      <path data-name="패스 17085" d="M6.116 1a5.116 5.116 0 1 0 5.116 5.116A5.116 5.116 0 0 0 6.116 1z" transform="translate(-.791 -.791)" style="stroke-miterlimit:10;fill:none;stroke-width:.6px" stroke="currentColor"/>
      <path data-name="선 118" transform="translate(5.325 .225)" style="stroke-linecap:round;stroke-miterlimit:10;fill:none;stroke-width:.6px" d="M0 10.088V0" stroke="currentColor"/>
      <path data-name="패스 17086" d="M5.914 35.212a7.81 7.81 0 0 1 4.075-.891 7.974 7.974 0 0 1 4.1.891" transform="translate(-4.677 -27.14)" style="stroke-linecap:round;stroke-miterlimit:10;fill:none;stroke-width:.6px" stroke="currentColor"/>
      <path data-name="패스 17087" d="M15.889 1c-1.4 0-2.53 2.291-2.53 5.116s1.133 5.116 2.53 5.116 2.53-2.291 2.53-5.116S17.286 1 15.889 1z" transform="translate(-10.564 -.791)" style="stroke-linecap:round;stroke-miterlimit:10;fill:none;stroke-width:.6px" stroke="currentColor"/>
      <path data-name="패스 17088" d="M5.914 12.321a7.81 7.81 0 0 0 4.075.891 7.974 7.974 0 0 0 4.1-.891" transform="translate(-4.677 -9.743)" style="stroke-linecap:round;stroke-miterlimit:10;fill:none;stroke-width:.6px" stroke="currentColor"/>
      <path data-name="선 119" transform="translate(.21 5.325)" style="stroke-linecap:round;stroke-miterlimit:10;fill:none;stroke-width:.6px" stroke="currentColor" d="M0 0h10.229"/>
    </g>
  </g>
</svg>
</template>
