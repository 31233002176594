<template>
<component v-if="!!component" :is="component" class="icon"/>
</template>

<script setup>
import { computed } from 'vue'
import * as src from './src'

const props = defineProps({
  iconName: { type: String, required: true },
});
const component = computed(() => {
  switch (props.iconName)
  {
    case 'logout':
      return src.logout;
    case 'home':
      return src.home;
    case 'menu':
      return src.menu;
    case 'arrow-down':
      return src.arrowDown;
    case 'arrow-right':
      return src.arrowRight;
    case 'x':
      return src.x;
    case 'download':
      return src.download;
    case 'chevron-left':
      return src.chevronLeft;
    case 'chevron-right':
      return src.chevronRight;
    case 'search':
      return src.search;
    case 'move':
      return src.move;
    case 'locale':
      return src.locale;
  }
  return null;
});
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  width: var(--icon-size, 24px);
  height: var(--icon-size, 24px);
  color: var(--icon-color, var(--color-base));
  stroke-width: var(--icon-stroke, 2);
}
</style>
