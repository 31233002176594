<template>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path d="M0 0h18v18H0z" fill="none"/>
  <g>
    <path d="M-16855.211-195.188v10.89" transform="translate(16864.075 196.64)" stroke="currentColor" stroke-width="1.25"/>
    <path d="m-16861.6-183.5 3.91 3.912 3.912-3.912" transform="translate(16866.555 191.815)" stroke="currentColor" stroke-width="1.25" fill="none"/>
    <path d="M-16866.842-172.888h13.969" transform="translate(16868.858 187.436)" stroke="currentColor" stroke-width="1.25" fill="none"/>
  </g>
</svg>
</template>
